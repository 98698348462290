export const firebaseConfig = {
    apiKey: "AIzaSyAjvLc5aZnUT60Cwm-nsF_SD9j_UU5c3Yo",
    authDomain: "sabib-b2a5d.firebaseapp.com",
    projectId: "sabib-b2a5d",
    storageBucket: "sabib-b2a5d.appspot.com",
    messagingSenderId: "110953503498",
    appId: "1:110953503498:web:97cfb3ad33f1783529b8f3",
    measurementId: "G-HXNH5NP2P1"
};

export default firebaseConfig;
